.instaImgStyle{
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 10px;
}

.imgStyle{
  border-radius: 10px;
  border: 1px;
  border-color: #ffffff;
}

.closeStyle{
  position: relative;
  top: 21px;
  left: 2px;
  cursor: pointer;
  z-index: 1;
  background: #ffffff;
  padding: 0 1px;
}